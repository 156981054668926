<template>
  <!-- TODO: icons -->
  <div v-if="!$store.state.authToken" class="geoff-bg-main p-3 h-full-view">
    <div>
      <div class="mb-1 fw-600 fs-18" :class="$store.state.isMobile?'mt-4':''">My Account</div>
      <div @click="$router.push({name: 'accountVerification'})" class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/security-user.svg')+')'}"></div>
          <div class="fs-14"> Verify Account </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'reactivateProfile'})" class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/arrow-counterclockwise.svg')+')'}"></div>
          <div class="fs-14"> Reactivate Account </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
      <div @click="$router.push({name: 'responsibleGambling'})" class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
        <div class="d-flex align-items-center justify-content-center">
          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/dice-5.svg')+')'}"></div>
          <div class="fs-14"> Responsible Gambling </div>
        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
      </div>
    </div>
      
    <div>
      <div class="mt-4 mb-1 fw-600 fs-18">Other Information</div>
        <a 
          v-for="(link, index) in API.OTHER_LINKS"
          :href="link.link"
          :target="link.isExternal ? '_blank' : '_self'"
          :key="index"
          class="other-link geoff-border-bottom-separator d-flex justify-content-between py-3 align-items-center cursor-pointer"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="fs-14"> {{link.label}} </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </a>
    </div>
  </div>
  <div v-else :class="isMobile?'geoff-bg-main p-3':''">
    <div :class="isMobile?'':'account-menu'">
      <div class="mb-1 fw-600 fs-18" :class="$store.state.isMobile?'mt-4':''">My Account</div>
        <!-- My activity -->
        <div
          @click="$router.push({name: 'myActivity'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'myActivity'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/chart-1.svg')+')'}"></div>
            <div class="fs-14"> My Activity </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>

        <div
          @click="$router.push({name: 'transactions'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'transactions'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/document-text.svg')+')'}"></div>
            <div class="fs-14"> My Bets </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>
        <div
          @click="$router.push({name: 'changePassword'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'changePassword'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/password-check.svg')+')'}"></div>
            <div class="fs-14"> Change Password </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>
        <div
          @click="$router.push({name: 'updateProfile'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'updateProfile'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/user-edit.svg')+')'}"></div>
            <div class="fs-14"> Update My Details </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>

        <div
          @click="$router.push({name: 'userPreferences'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'userPreferences'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/setting-3.svg')+')'}"></div>
            <div class="fs-14"> User Preferences </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>

        <div
          @click="$router.push({name: 'accountVerification'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'accountVerification'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/security-user.svg')+')'}"></div>
            <div class="fs-14"> Verify Account </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>
  <!--      <div @click="$router.push({name: 'reactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
  <!--        <div class="d-flex align-items-center justify-content-center">-->
  <!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/arrow-counterclockwise.svg')+')'}"></div>-->
  <!--          <div class="fs-14"> Reactivate Account </div>-->
  <!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
  <!--      </div>-->
  <!--      <div @click="$router.push({name: 'deactivateProfile'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
  <!--        <div class="d-flex align-items-center justify-content-center">-->
  <!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/clipboard-close.svg')+')'}"></div>-->
  <!--          <div class="fs-14"> Deactivate Account </div>-->
  <!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
  <!--      </div>-->
        <div
          @click="$router.push({name: 'responsibleGambling'})"
          class="geoff-border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'responsibleGambling', 'geoff-border-bottom': isMobile}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/dice-5.svg')+')'}"></div>
            <div class="fs-14"> Responsible Gambling </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>

    </div>
    <div :class="isMobile?'':'account-menu'">
      <div class="mb-1 fw-600 fs-18" :class="isMobile?'mt-4':''">Payments</div>

        <div
          @click="$router.push({name: 'deposit'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': ('deposit', 'deposit-result').includes($route.name)}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/card-tick.svg')+')'}"></div>
            <div class="fs-14"> Deposit </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>
  <!--      <div @click="$router.push({name: 'account'})" class="border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">-->
  <!--        <div class="d-flex align-items-center justify-content-center">-->
  <!--          <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/wallet-3.svg')+')'}"></div>-->
  <!--          <div class="fs-14"> Online Deposit </div>-->
  <!--        </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">-->
  <!--      </div>-->
        <div
          @click="$router.push({name: 'withdrawal'})"
          class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'withdrawal'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/convert-card.svg')+')'}"></div>
            <div class="fs-14"> Request Withdrawal </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>
        <div
          @click="$router.push({name: 'updateDepositLimit'})"
          class="geoff-border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer"
          :class="{'active': $route.name === 'updateDepositLimit', isMobile: 'geoff-border-bottom'}"
        >
          <div class="d-flex align-items-center justify-content-center">
            <div class="menu-item-icon me-2" :style="{backgroundImage: 'url('+require('../assets/img/account/trend-up.svg')+')'}"></div>
            <div class="fs-14"> Update Deposit Limits </div>
          </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
        </div>

      </div>
    <div v-if="['ios', 'android'].includes($store.state.platform)" :class="isMobile?'':'account-menu'">

      <div class="mb-1 fw-600 fs-18" :class="isMobile?'mt-4':''">Biometric Authentication</div>
        <div v-if="['ios', 'android'].includes($store.state.platform)" class="geoff-border-bottom border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer">
          <div class="d-flex align-items-center justify-content-center">
            <div class="fs-14"> Use Biometric Authentication </div>
          </div>
          <vue-switch v-model="useBiometricAuth" />
        </div>
        <!-- <div class="border-1 d-flex justify-content-between py-3 align-items-center cursor-pointer" :class="isMobile?'geoff-border-bottom':''">
          <div class="d-flex align-items-center justify-content-center">
            <div class="fs-14"> Decimal Odds Format </div>
          </div>
          <vue-switch @click="updateOddsFormat" v-model="decimalFormat" />
        </div> -->
    </div>

    <div :class="isMobile?'':'account-menu'">
        <div class="mt-4 mb-1 fw-600 fs-18">Other Information</div>

          <a 
            v-for="(link, index) in API.OTHER_LINKS"
            :href="link.link"
            :target="link.isExternal ? '_blank' : '_self'"
            :key="index"
            class="other-link geoff-border-bottom-separator d-flex justify-content-between py-3 align-items-center cursor-pointer"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="fs-14"> {{link.label}} </div>
            </div><img alt="icon" :src="require('../assets/to-right-arrow.svg')">
          </a>
    
      </div>


    <VueButton  class="my-4 mt-5" cls="main" @click="logout">Log Out</VueButton>
  </div>
</template>

<script>
import VueButton from "@/components/UI/VueButton";
import {mapActions, mapGetters} from "vuex";
import VueSwitch from "@/components/UI/VueSwitch";
import {NativeBiometric} from "capacitor-native-biometric";
export default {
name: "AccountView",
  components: {VueSwitch, VueButton},
  data: ()=>{
    return{
      decimalFormat: false,
      useBiometricAuth: false,
    }
  },
  computed:{
    ...mapGetters(['isMobile'])
  },
  beforeMount(){
    this.useBiometricAuth = JSON.parse(localStorage.getItem('useBiometricLogin'))
    let timeout = 0
    if (this.$store.state.user.oddsFormat === undefined) timeout = 1500
    setTimeout(()=>{
      if (this.$store.state.user.oddsFormat==='decimal') this.decimalFormat = true
    }, timeout)
  },
  watch:{
    useBiometricAuth(value){
      if (value) {
        localStorage.setItem('useBiometricLogin', 'true')
      } else {
        localStorage.setItem('useBiometricLogin', 'false')
      }
    }
  },
  methods: {
    ...mapActions(['LOGOUT']),
    logout(){
      this.LOGOUT().then(()=>{
        if(this.$store.state.platform !== "web") NativeBiometric.deleteCredentials('geoffbanks.bet')
      })
      this.$router.push({name: 'login'})
    },
    updateOddsFormat(){
      const vm = this
      const oddsFormat = this.decimalFormat?'decimal':'fractional'
      this.API.updateOddsFormatInPreferences(oddsFormat)
      .then(res=>{
        console.log('ODDS FORMAT UPDATED', res)
        vm.$store.dispatch('SET_USER_DATA', {oddsFormat: oddsFormat})
      })
      .catch(error=>{
        vm.$toast.error(error)
        vm.decimalFormat = !vm.decimalFormat
      })
    }
  }

}
</script>

<style scoped lang="scss">
.account-menu{
  background: var(--geoff-bg-section);
  padding: 1rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
}
.account-menu > div {
  margin: 0 -1rem;
  padding: 0 1rem;
}
.account-menu > .active {
  background: var(--geoff-bg-active);
}
.menu-item-icon{
  height: 1.75rem;
  width: 1.75rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(100%);
}

.other-link {
  text-decoration: none;
  color: var(--geoff-link-1);

  &:hover {
    color: var(--geoff-link-1);
  }
}
</style>