<template>
  <div class="p-3 h-full-view">
    <pre-loader v-if="loading"/>
    <div class="deposit-wrap m-auto" v-show="!loading">
      <div v-if="isDevelopmentMode" class="alert alert-danger my-3" role="alert">Payment works in test mode!</div>
      <div v-if="result==='success'">
        <div class="success-picture" :style="{backgroundImage: 'url('+require('../../assets/img/checkCircle.png')+')'}"></div>
        <div class="fs-3 text-success text-center my-4">Payment completed successfully!</div>
        <vue-button cls="main" @click="$router.push({name: 'home'})">Return to the homepage</vue-button>
      </div>
      <div v-else-if="result==='failed'">
        <div class="failed-picture" :style="{backgroundImage: 'url('+require('../../assets/img/warningIcon.png')+')'}"></div>
        <div class="fs-3 text-danger text-center my-4">{{ errorMessage }}</div>
        <vue-button cls="main" @click="reload">Try again</vue-button>
        <vue-button class="mt-3" @click="$router.push({name: 'home'})">Return to the homepage</vue-button>
      </div>
      <!-- legal info -->
      <div class="mt-3 fs-12 grid-legal-info desktop-gap ">
        <div class="col-label">Registered company name:</div><div class="col-info">GB Sports Advisors Ltd</div>
        <div class="col-label">Registered Address:</div><div  class="col-info">328-334 Molesey Road, Walton-On-Thames, Surrey, United Kingdom, KT12 3PD</div>
        <div class="col-label">Country:</div><div  class="col-info">United Kingdom</div>
        <div class="col-label">Email address:</div><div  class="col-info"> <a href="mailto:support@geoffbanks.bet">support@geoffbanks.bet</a> </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters } from "vuex";
  import VueButton from "@/components/UI/VueButton";
  import PreLoader from "@/components/UI/PreLoader";
  
  export default {
    name: "DepositResult",
    components: {
      PreLoader,
      VueButton
    },
    data:()=>{
      return{
        result: '',
        errorMessage: 'Payment declined!',
        loading: true,
        checkoutId: null,
        isDevelopmentMode: false,
      }
    },
    computed: {
      ...mapGetters(['CURRENCY', 'USER']),
     
    },
    mounted() {
      this.isDevelopmentMode = process.env.VUE_APP_MODE === 'development'
     
      if (this.$route.query.id){
        this.checkoutId = this.$route.query.id

        this.getPaydooPaymentStatus()
        // remove checkout id from the query: it might be used only once to validate status
        // so reload the url will cause failed result
        this.$router.replace({query: undefined}) 
      } else {
        this.$router.push({name: 'deposit'})
      }
    },
    methods:{

      reload(){
        this.$router.push({name: 'deposit'})
      },

      getPaydooPaymentStatus() {
        const vm = this
        vm.loading = true
        const username = JSON.parse(localStorage.getItem('userCreds')).username
        vm.API.getPaydooPaymentStatus(vm.checkoutId, username, this.$route.params.provider)
        .then(data=>{
          const successfullyRegex = /^(000\.000\.|000\.100\.1|000\.[36])/
          const successfullyManuallyRegex = /^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)/
          const pendingStatusRegex = /^(000\.200)/
          // const formatValidationError = /^(200\.[123]|100\.[53][07]|800\.900|100\.[69]00\.500)/
          console.log('CODE', data.result.code)
          console.log('SUCCESS', successfullyRegex.test(data.result.code) || successfullyManuallyRegex.test(data.result.code))
          if (successfullyRegex.test(data.result.code) || successfullyManuallyRegex.test(data.result.code)) {
            vm.result = 'success'
          } else {
            if (pendingStatusRegex.test(data.result.code)) {
              vm.errorMessage = 'Transaction pending'
              setTimeout(()=>vm.getPaydooPaymentStatus(), 2000)
            }
            vm.result = 'failed'
          }
        })
        .finally(()=>vm.loading=false)
      },

    }
  }
  </script>
  
  <style scoped lang="scss">
  .content-deposit{
    position: fixed;
    top: calc(3.5rem + env(safe-area-inset-top));
    bottom: 3.5rem;
    left: 0;
    right: 0;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
  }
  .deposit-wrap{
    max-width: 400px;
  }
  .success-picture, .failed-picture{
    height: 20vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 4rem 0 2rem;
  }
  </style>
  