<template>
  <transition name="fade">
    <div class="popup-modal" v-if="isVisible" @click.self="noClose?undefined:close()">
      <div class="window position-relative overflow-hidden" :class="{'window-dark': dark}">
        <div class="position-absolute top-0 start-0 right-0 w-100"><slot name="header"></slot></div>
        <div class="window-content">
        <div
          v-if="!noClose"
          class="position-absolute fs-3"
          style=" top: 0.3rem;right: 1rem; z-index: 150"
          @click="close"
        >
          <a><i class="bi bi-x"></i></a>
        </div>
        <div class="pt-4">
          <slot></slot>
        </div>
      </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'VueModal',
  // props: ['noClose','dark'],
  props: {
    noClose: {
      type:Boolean
    },
    dark: {
      type: Boolean
    }
  },
  data: () => ({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped>
/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.popup-modal {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem;
  align-items: center;
  z-index: 1100;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.window {
  margin: 6rem auto;
  /*position: absolute;*/
  /*top: 6rem;*/
  /*bottom: 6rem;*/
  /*left: 50%;*/
  /*transform: translate(-50%,0);*/
  /* background: #FFFFFF; */
  background: var(--geoff-bg-main);
  border-radius: 0.5rem;
  box-shadow: 2px 4px 8px rgb(0 0 0 / 20%);
  max-width: max-content;
  padding: 0.75rem;
  min-width: 320px;
}
.window-dark{
  background: #09080C;
}


.window-content{
  height: 100%;
}
/* md-устройства (больше или равно 768px) */
@media (min-width: 768px) {
    .window {
  padding: 1.75rem;
  min-width: 400px;
}

}
</style>