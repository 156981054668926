<template>
  <div v-if="errors" class="p-3">
    <div class="my-3 fs-18 fw-600 text-center">Bet Slip is not submitted!</div>
    <ul v-if="errorMessages.length || detailedErrors.length" class="my-3 fs-14 text-center mb-5">
      
      <!-- Detailed erros -->
      <li v-for="(error, index) in detailedErrors" :key="index" class="error-item left">
        <div v-if="error.message1" class="message message-top">{{ error.message1 }}</div>
        <template v-if="error.type === 4"> 
          <!-- {{error.text}} -->
          <div class="price-change-container"> 
            <div></div>
            <div>OLD PRICE</div>
            <div>NEW PRICE</div>
            <hr />
            <template v-for="(item,idx) in error.betErrors" :key="idx">
              <div class="lbl">
                <div>{{ item.betDetail }}</div>
                <div class="note">{{ item.betName }} @ {{ item.betPriceCode }}</div>
              </div>
              <div>{{ glb.formatPrice(item.betPrice) }}</div>
              <div>{{ glb.formatPrice(item.betNewPrice) }}</div>
              <hr v-if="error.betErrors?.length > (idx + 1)"/>
            </template>
          </div>
        </template>
        <template v-else> 
          <div class="price-change-container cols-2"> 
            <template v-for="(item,idx) in error.betErrors" :key="idx">
              <div class="lbl">
                <div>{{ item.betDetail }}</div>
                <div class="note">{{ item.betName }} @ {{ item.betPriceCode }}</div>
              </div>
              <div>{{ glb.formatPrice(item.betPrice) }}</div>
              <hr v-if="error.betErrors?.length > (idx + 1)"/>
            </template>
          </div>
        </template>
        <div v-if="error.message2" class="message message-bottom">{{ error.message2 }}</div>
      </li>

      <!-- basic errors: simple message -->
      <li v-for="(error, index) in errorMessages" :key="index" class="mb-2 message-left">
        <!-- custom  LiabilityExceeded error-->
        <div v-if="error?.isLiabilityExceeded"> 
          Sorry, the maximum stake has been exceeded. 
          <span v-if="error.maxStakeAvailable">The maximum stake available is {{ CURRENCY.symbol }}{{error.maxStakeAvailable}}.</span>
          If you keep on seeing this message and believe it is in error, please <router-link class="footer-link" :to="{name:'info', params: { infoType: 'Help' }}">contact us</router-link>. Thank you.
        </div>
        <div v-else>{{error}}</div>
      </li>
      
    </ul>
    <vue-button v-if="isShowAcceptPriceChangeButton" @click="acceptPriceChangesResubmit" class="fw-600 mb-2" cls="main">Accept Price Changes and Place Bet</vue-button>
    <vue-button @click="closeErroResponse" class="fw-600" cls="main">Back to Betslip</vue-button>
  </div>
  <div v-else class="p-3">
      <p class="fs-18 fw-600 my-4">You have successfully placed {{ betslipResponse.bets.length }} bet(s):</p>
      <ol v-if="betslipResponse.bets.length" class="fs-14">
        <li v-for="(bet, index) in betslipResponse.bets" :key="index" class="d-flex justify-content-between mb-2">
          <div><span class="me-2">{{index+1}}</span>{{bet.detail +' @ ' + bet.name}}</div>
          <div>{{ glb.formatPrice(bet.price) +' '+ bet.newPC }}</div>
        </li>
      </ol>
      <div v-if="betslipResponse.wagersSubmitResult.length" class="border-top border-bottom py-3 fs-14">
        <ul class="placed-receipt-list">
          <li v-for="(wager, index) in betslipResponse.wagersSubmitResult" :key="index" class="">
            <div class="d-flex mb-2 justify-content-between">
              <div class="d-flex"><span class="me-2">{{wager.name}}</span> <info-icon>{{ wager.isEachWay ? 'Each Way' : 'Win only' }}</info-icon></div>
              <div>{{ CURRENCY.symbol }}{{ wager.stakePlaced  }}</div>
            </div>
            <div class="fs-14 mb-2 d-flex justify-content-between">
              <div>Ticket Number</div>
              <div> {{ wager.ticketNumber }}</div>
            </div>
            <div class="fs-14 mb-2 d-flex justify-content-between">
              <div>Potential Return</div>
              <div> {{ CURRENCY.symbol }}{{ wager.potentialReturn }}</div>
            </div>
          </li>
        </ul>
      </div>
    <div class="fs-14 border-top py-3 mb-4">
      <div class="d-flex mb-2 justify-content-between fw-600">
          <div>Total Payment</div>
          <div class="ms-2">{{ CURRENCY.symbol }}{{ successTotalPayment }}</div>
      </div>
      <div class="d-flex mb-2 justify-content-between fw-600">
          <div>Current Balance</div>
          <div class="ms-2">{{ CURRENCY.symbol }}{{ betslipResponse.customerBalance || 0 }}</div>
      </div>
    </div>
      <vue-button @click="closeSuccessResponse" class="fw-600" cls="main">OK</vue-button>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import VueButton from "@/components/UI/VueButton";
  import {detectBetslipErrors} from "@/services/betslip/_helpers";
  import InfoIcon from "@/components/UI/InfoIcon";
  
  export default {
    name: "BetSlipSubmitResponse",
    emits: ['updatestatus'],
    components: {InfoIcon, VueButton},
    props: ['betslipResponse'],
    data:()=>{
      return{
        errors: null,
      }
    },
    mounted() {
      let errors = detectBetslipErrors(this.betslipResponse, true)
      console.log(errors)
      if (errors.isAnyError) {
        this.errors = errors
        this.$emit('updatestatus', 'failure')
      } else {
        this.$emit('updatestatus', 'success')
      }
    },
    computed:{
      ...mapGetters(['CURRENCY']),
      successTotalPayment(){
        return (this.betslipResponse.wagersSubmitResult || [])
          .reduce((sum, next) => (sum + next.stakePlaced), 0)
      },
      isShowAcceptPriceChangeButton() {
        return this.betslipResponse.betsValidation?.some(el => el.type === 4)
      },
      detailedErrors() {
        return this.betslipResponse.betsValidation || []
      },
      errorMessages() {
        if (!this.errors) return []
        let messages = []
  
        if (this.errors.isRestrictedSport)            messages.push(`Your account is not eligible to place bets at ${this.betslipResponse.restriction.prohibitedSportcodes.join(', ')}. Please see your email for more information.`)
        if (this.errors.isRestrictedBetType)          messages.push(`Your account is not eligible to place bets at ${this.betslipResponse.restriction.prohibitedPriceTypes.join(', ')}. Please see your email for more information.`)
        if (this.errors.isRestrictedMaxStake)         messages.push(`Maximum total stake exceeded. Maximum allowed stake is ${this.CURRENCY.symbol}${this.betslipResponse.restriction.maxStakeAllowed}.`)
        if (this.errors.isEventEnded)                 messages.push('Event has ended. The selection is no longer available.')
        if (this.errors.isPriceChanged)               messages.push(`Price has changed! Old price ${this.glb.formatPrice(this.betslipResponse.oldPrice)}. New price ${this.glb.formatPrice(this.betslipResponse.price)}`)
        if (this.errors.isLiabilityExceeded) {
          // custom html message
          const maxStakeAvailable = this.betslipResponse?.wagersSubmitResult?.find(el => el.isLiabilityExceeded)?.maxStakeAvailable
          // if (!maxStakeAvailable)                     messages.push('Sorry, the maximum stake has been exceeded. If you keep on seeing this message and believe it is in error, please contact us. Thank you.')
          // else                                        messages.push(`Sorry, the maximum stake has been exceeded. The maximum stake available is £${this.betslipResponse?.wagersSubmitResult?.find(el => el.isLiabilityExceeded)?.maxStakeAvailable}.  If you keep on seeing this message and believe it is in error, please contact us. Thank you.`)
          if (!maxStakeAvailable)                     messages.push({isLiabilityExceeded: true, maxStakeAvailable: null})
          else                                        messages.push({isLiabilityExceeded: true, maxStakeAvailable: maxStakeAvailable})
        }
        // if (this.errors.isLiabilityExceeded)          messages.push('Sorry, the maximum online liability has been exceeded. Should you wish to place more than the permitted stake offered. Please call the office on 01344 873334. Thank you.')
        // if (this.errors.isLiabilityExceeded)          messages.push(`Sorry, the maximum online liability has been exceeded. The maximum stake available is £${this.betslipResponse?.wagersSubmitResult?.find(el => el.isLiabilityExceeded)?.maxStakeAvailable}. Should you wish to place a higher stake, please call the office on 01344 873334. Thank you.`)
        if (this.errors.isInsufficientFunds)          messages.push('You have insufficient Funds! Top up the your balance!')
        if (this.errors.isSelectionNoLongerAvailable) messages.push('Selection is no longer available.')
        // if (this.errors.isBetslipFailedInGeneral)     messages.push('')
        // if (this.errors.isNothingToSubmit)            messages.push('')
        if (!messages.length)                         messages.push(this.betslipResponse.failureReason || this.betslipResponse.message || '-')
        return messages
      }
    },
    methods:{
      closeSuccessResponse(){
        this.$store.dispatch('CLEAR_BETSLIP')
        this.$emit('init')
        this.$emit('close')
      },
      closeErroResponse(){
        this.$emit('init')
        // this.$emit('close')
        
        if (this.errors.isRestrictedMaxStake) {
          this.$emit('close', {
            isError: true,
            limit: this.betslipResponse.restriction.maxStakeAllowed
          })
        }
  
        if (this.errors.isLiabilityExceeded) {
          this.$emit('close', {
            isError: true,
            limit: this.betslipResponse?.wagersSubmitResult?.find(el => el.isLiabilityExceeded)?.maxStakeAvailable
          })
        }
        else {
          this.$emit('close', {
            isError: true
          })
        }
      },
      acceptPriceChangesResubmit() {
        this.$emit('init')
        this.$emit('close', {
          isResubmit: true
        })
      },
      logout(){
        this.$store.dispatch('LOGOUT')
      },
    }
  }
  </script>
  
  <style scoped lang="scss">
  .error-item {
    margin-bottom: 2rem;
  }  
  .message {
    text-align: left;
    &.message-top {
      margin-bottom: 0.25rem;
      font-weight: 700;
    }
    &.message-bottom {}
    &.left {
      text-align: left;
    }
    
  }
  .message-left {
    text-align: left;
  }
  .price-change-container {
  
    // .item {
      display: grid;
      grid-template-columns: 1fr auto auto;
      align-items: center;
      grid-gap: 0.25rem;
      margin-bottom: 0.25rem;
    // }

    hr {
      grid-column: 1 / 4;
      padding: 0;
      margin: 0;
    }

    &.cols-2 {
      grid-template-columns: 1fr auto;

      hr {
        grid-column: 1 / 3;
      }
    }
  
    .lbl {
      white-space: nowrap;
      text-align: left;
      .note {
        color: var(--geoff-txt-note);
      }
    }
   
  }
  
  </style>