<template>
  <div :class="{'position-relative': isMobile}">
    <div v-if="loading">
      <div class="loading-shade"></div>
      <pre-loader></pre-loader>
    </div>

    <div  class="form-page" :class="{'p-3': isMobile}">
      <form @submit.prevent="submitForm" autocomplete="off" novalidate="novalidate">
        <div class="header">
          <div class="title">Reactivate My Account</div>
          <div>Please use this form to reactivate your account after Account Deactivation/Closure/Take A Break/Self Exclusion period.</div>
        </div>

        <div class="field-wr">
          <div class="label lbl-required">Name</div>
          <div class="columns-2 max-width-field">
            <vue-input
              class="col-input"
              name="first-name"
              type="text"
              containerClass=""
              v-model="model.firstName"
              placeholder="First Name"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'FirstName')}"
            />
            <vue-input
              class="col-input"
              name="last-name"
              type="text"
              containerClass=""
              v-model="model.lastName"
              placeholder="Last Name"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'LastName')}"
            />
          </div>
        </div>

        <div class="field-wr">
          <div class="label lbl-required">Date of Birth</div>
          <datepicker
            auto-position
            auto-apply
            :max-date="maxDate"
            :start-date="maxDate"
            prevent-min-max-navigation
            :enable-time-picker="false"
            v-model="model.dob"
            label="Date of Birth"
            :class="{'form-field-error': fieldsValidation.some(el => el.field === 'DateOfBirth')}"
          />
        </div>

        <div class="field-wr">
          <div class="label">Username</div>
          <div class="money-container max-width-field">
            <vue-input
              class="money-input"
              name="user-name"
              type="text"
              v-model="model.userName"
              placeholder="Username"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Username')}"
            />
          </div>
        </div>

        <div class="field-wr">
          <vue-input
          class="txt-input"
          name="email"
          type="email"
          autocomplete="false"
          v-model="model.email"
          label="Email"
          label-class="lbl-required"
          max-length="320"
          :right-content="true"
          :update-on-input="true"
          :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Email')}"
          >
          </vue-input>
        </div>

        <div class="field-wr">
          <div class="label">I Acknowledge that my account exclusion period has expired, or that I simply deactivated or closed my account previously, and am reopening my Geoff Banks account of my own choice and free will.</div>
          <div class="label">I agree that if I am reactivating my account after a self exclusion or take a break period, that I will not attempt to use the account for gambling for a period of not less than 24 hours.</div>
          <div class="text-container max-width-field">
            <vue-checkbox 
              class="checkbox-input"
              v-model="model.isTermsAccepted"
              description="'terms'"
              name="cbx-terms"
              label-class="w-100"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'TermsAgreement')}"
            >
              <div class="cbx-label lbl-required">I Agree</div>
            </vue-checkbox>
          </div>
        </div>

        <div v-if="validationErrors.length" class="geoff-text-danger mt-3">
          <div v-for="(err, idx) in validationErrors" :key="idx">
            <div>{{ err }}</div>
          </div>
        </div>

        <vue-button cls="main" class="my-5">Submit</vue-button>

      </form>
    </div>

    
    <vue-modal no-close ref="successModal" class="success-modal" :class="{ 'position-absolute': !isMobile }">
      <template v-slot:header>
        <div class="modal-title">
          Many Thanks.
        </div>
      </template>
      <template v-slot:default>
        <div class="modal-main-content">
          
          <div>You will hear from us via email regarding your account reactivation.</div>
          <div>Please note, we will apply a further 24 hour period to the exclusion to that you can be sure this is precisely what you want to do here.</div>
          <div>Kind Regards <br/> Geoff Banks</div>

          <vue-button 
            cls="main" 
            @click="closeSuccessModal" 
            class="mt-5">OK</vue-button>
        </div>
      </template>
    </vue-modal>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import PreLoader from "@/components/UI/PreLoader";
  import VueInput from "@/components/UI/VueInput";
  import VueButton from "@/components/UI/VueButton";
  import Datepicker from '@vuepic/vue-datepicker';
  import VueCheckbox from "@/components/UI/VueCheckbox";
  import VueModal from "@/components/UI/VueModal";

  export default {
    name: "ReactivateAccountView",
    components: {
      PreLoader,
      VueInput,
      VueButton,
      Datepicker,
      VueCheckbox,
      VueModal
    },
    data:()=>{
      return{
        loading: false,
        isSuccess: true,
        model: {
          firstName: '',
          lastName: '',
          userName: '',
          dob: null,
          email: '',
          isTermsAccepted: false
        },
        maxDate: new Date((new Date()).setFullYear((new Date()).getFullYear()-18)),

        frontErrors: [],
        backErrors: [],
        backValidErrors: []
      }
    },
    computed: {
      ...mapGetters(['isMobile']),
      validationErrors () {
        return this.fieldsValidation.map(el => el.message)
      },
      fieldsValidation () {
        if (this.frontErrors?.length) {
          return this.frontErrors
        }
        if (this.backErrors?.length) {
          return this.backErrors
        }
        if (this.backValidErrors) {
            const res = Object.keys(this.backValidErrors).map(el => { return {
              field: el,
              message: this.backValidErrors[el].find((el => el))
            }}).flat()
            return res
          }
        return []
      },
    },
   
    methods: {
      validateForm() {
        const errors = []

        // NAME
        if (!this.model.firstName) {
          errors.push({
            field: 'FirstName',
            message: 'First Name is required.'
          })
        }
        if (!this.model.lastName) {
          errors.push({
            field: 'LastName',
            message: 'Last Name is required.'
          })
        }
        if (!this.model.dob) {
          errors.push({
            field: 'DateOfBirth',
            message: 'Date of Birth is required.'
          })
        }

        // USERNAME
        // no username validation
        // if (this.model.userName) {
        //   const re =  /^[a-z0-9]+$/i // letters and numbers
        //   if (!re.test(this.model.userName)) {
        //     errors.push({
        //       field: 'Username',
        //       message: 'User Name must contains only lettes and numbers.'
        //     })
        //   }
        //   if (this.model.userName?.trim()?.length > 8 || this.model.userName?.trim()?.length < 6) { // length between 6 and 8
        //     errors.push({
        //       field: 'Username',
        //       message: 'User Name must be min 6 and max 8 characters.'
        //     })
        //   }
        // }

        //EMAIL
        if (!this.model.email) {
          errors.push({
            field: 'Email',
            message: 'Email is required.'
          })
        }
        else {

          if ( !this.API.emailRegEx.test(this.model.email)) {
            errors.push({
              field: 'Email',
              message: 'Please, enter valid email.'
            })
          }

        }

        // TERMS
        if (!this.model.isTermsAccepted) {
          errors.push({
            field: 'TermsAgreement',
            message: "You must agree to terms and conditions to continue!"
          })
        }

        return errors;
      },
      
      submitForm() {
        // validate
        const validationResult =  this.validateForm()
        if (validationResult.length) {
          this.frontErrors = validationResult
          this.$toast.error('One or more fields are invalid. Please fix and try again.')
          return
        }
        else {
          this.frontErrors = []
        }
      
        const model = {
          firstName: this.model.firstName,
          lastName: this.model.lastName,
          dateOfBirth: this.model.dob,
          email: this.model.email,
          termsAgreement: this.model.isTermsAccepted,
          ...(this.model.userName ? { userName: this.model.userName } : {})
        }
    
        this.loading = true;

        this.API.postAccountReactivation(model)
          .then((resp) => {
            if (resp.isFormSent) {
              this.backErrors = null
              this.openSuccessModal()
            }
            else {
              this.backErrors = resp?.fileValidationErrors
              this.$toast.error('One or more fields are invalid. Please fix and try again.')
            }
          })
          .catch(err => {
            console.log('FORM SUBMIT ERROR', err?.response?.data?.errors)
            if (err?.response?.data?.errors) {
              this.backValidErrors = err.response.data.errors
            }
            else {
              this.$toast.error('Service is unavailable. Please try again later.')
            }
          })
          .finally(()=>{
            this.loading = false
          })
      },

      clearForm() {
        this.model.firstName = ''
        this.model.lastName = ''
        this.model.userName = ''
        this.model.dob = null,
        this.model.email = '',
        this.model.isTermsAccepted = false
      },

      openSuccessModal() {
        this.$refs.successModal.open()
      },
      closeSuccessModal() {
        this.$refs.successModal.close()
        this.clearForm()
      },
    }
  }
</script>

<style scoped lang="scss">
  .form-page {
    .header {
      .title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
    
    .field-wr {
      margin-bottom: 1rem;
      .label {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;

        &.lbl-thin {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
      .field-label {
        font-size: 14px;
        line-height: 17px;
      }
      .note {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--geoff-txt-info);
      }

      .columns-2 {
        display: flex;
        flex-grow: 1;
        align-items: center;
        gap: 1.5rem;
        .col-input {
          flex-grow: 1;
          margin: 0 0 !important;
        }
      }

      // file input
      .file-input {
        .file-label {
          padding: 0.5rem 4rem 0.5rem 1rem !important;
        }
      }
    }
  }

  .success-modal {
    font-size: 1rem;
   
    .modal-title {
      font-weight: 700;
      text-align: center;
      padding: 1rem 0;
    }
    .modal-main-content {
      text-align: center;
      font-size: 1.1rem;
      margin: 1rem 0;
      max-width: 60vw;
      @media only screen and (max-width: 1024px){
        max-width: unset;
      }

      >div {
        margin: 1rem
      }
    }
  }


</style>