<template>
<div class="container-fluid desktop-container">
  <div class="row py-3">
    <div class="col-2">
      <account-view/>
    </div>
    <div class="col-7 px-0 position-relative">
      <div :class="{
        'geoff-bg-section rounded-3 overflow-hidden p-3': !(['transactions', 'transactionsOld', 'myActivity'].includes($route.name)),
        'position-relative': ['reactivateProfile'].includes($route.name)
      }">
        <router-view/>
      </div>
    </div>
    <div class="col-3">
      <div class="geoff-bg-section rounded-3">
        <download-app class="p-3"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AccountView from "@/views/AccountView";
import DownloadApp from "@/components/DownloadApp";
export default {
  name: "BaseAccountDesktopView",
  components: {DownloadApp, AccountView}
}
</script>

<style scoped>
.desktop-container{
  /* margin-top: 5rem; */
  /* background-color: var(--geoff-bg-main) */
  margin-top: var(--geoff-header-height);
}
</style>