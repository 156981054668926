import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css'
import * as API from "./http/api"
import * as ENUMS from "./http/enums"
import mitt from 'mitt'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import {global_objects} from "./global"
import {Toaster} from "@meforma/vue-toaster";
import {IonicVue} from '@ionic/vue';
import '@ionic/core/css/ionic.bundle.css';
import "./assets/css/normalize.css"
import "./assets/css/main.css"
// import "./assets/css/thememain.css"
import "./assets/css/themedark.css"
import "./assets/css/theme.scss"
import OneSignalInit from "@/services/oneSignal";
import OneSignal from '@onesignal/onesignal-vue3'
import { load } from '@fingerprintjs/botd'

const app = createApp(App)

app.use(OneSignal, {
  appId: "52a42fc7-bc92-4848-9b1f-b458076b6938"
  // allowLocalhostAsSecureOrigin: true
})

// Global events
const emitter = mitt()
app.config.globalProperties.emitter = emitter

// Toaster
const toastOptions = {
  position: "top-center",
  maxToasts: 5,
  newestOnTop: true,
  hideProgressBar: true,
  timeout: 5000
}
app.use(Toast, toastOptions)

// Global functions & API
app.config.globalProperties.glb = global_objects
app.config.globalProperties.API = API
app.config.globalProperties.ENUMS = ENUMS



app.use(VueAxios, axios)
app.use(store)
app.use(Toaster, {position: 'top'})
app.use(router)
app.use(IonicVue)


router.isReady().then(() => {
  app.mount('#app');
})

// init OneSignal service
 document.addEventListener("deviceready", OneSignalInit, false);

// botD
load()
  .then((botd) => botd.detect())
  .then((result) => {
    console.log('BotD result', result)
    if ( result.bot) {
      window.gtag('event', 'bot_d_detect', {
        event_category: "Bot D",
        event_action: 'Bot detected',
        bot_kind: result.botKind
      });
    }
  })
  .catch((error) => console.error(error))


