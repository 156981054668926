<template>
  <div class="position-relative">
    <!-- <pre-loader v-if="loading"></pre-loader> -->
    <div v-if="loading">
      <div class="loading-shade"></div>
      <pre-loader></pre-loader>
    </div>

    <div class="form-page position-relative" :class="{'p-3': $store.state.isMobile}">
      <form @submit.prevent="submitForm" autocomplete="off" novalidate="novalidate">
        <div class="header">
          <div class="title">Account Verification Upload</div>
          <div>Please use the below upload tools to upload the relevant documentation.</div>
        </div>

        <div class="field-wr">
          <div class="label lbl-required">Name</div>
          <div class="columns-2 max-width-field">
            <vue-input
              class="col-input"
              name="first-name"
              type="text"
              containerClass=""
              v-model="model.firstName"
              placeholder="First Name"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'FirstName')}"
            />
            <vue-input
              class="col-input"
              name="last-name"
              type="text"
              containerClass=""
              v-model="model.lastName"
              placeholder="Last Name"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'LastName')}"
            />
          </div>
        </div>

        <div class="field-wr">
          <div class="label lbl-required">Username</div>
          <div class="money-container max-width-field">
            <vue-input
              class="money-input"
              name="user-name"
              type="text"
              v-model="model.userName"
              placeholder="Username"
              :class="{'form-field-error': fieldsValidation.some(el => el.field === 'Username')}"
            />
          </div>
        </div>

        <div class="field-wr">
          <div class="max-width-field">
            <vue-file-input
              class="file-input"
              type="file"
              name="proof-of-identity"
              :value="model.fileNameIdentity"
              label="Identity Verification"
              accept=".png, .jpg, .jpeg, .pdf"
              @change="loadFile($event, 'fileNameIdentity', 'fileIdentity')"
              :class="{'form-field-error': fieldsValidation.some(el => el.propertyName === 'IdentityVerificationFile')}"

            />
          </div>
          <div class="note">Please upload a photograph of yourself holding your passport/driving licence with the photo visible. (10Mb max)</div>
        </div>

        <div class="field-wr">
          <div class="max-width-field">
            <vue-file-input
              class="file-input"
              type="file"
              name="proof-of-address"
              :value="model.fileNameAddress"
              label="Address Verification"
              accept=".png, .jpg, .jpeg, .pdf"
              @change="loadFile($event, 'fileNameAddress', 'fileAddress')"
              :class="{'form-field-error': fieldsValidation.some(el => el.propertyName === 'AddressVerificationFile')}"
            />
          </div>
          <div class="note">Please upload a recent utility bill for your registered address, no older than six months. (10Mb max)</div>
        </div>

        <div class="field-wr">
          <div class="max-width-field">
            <vue-file-input
              class="file-input"
              type="file"
              name="proof-of-bank"
              :value="model.fileNameBank"
              label="Bank Verification"
              accept=".png, .jpg, .jpeg, .pdf"
              @change="loadFile($event, 'fileNameBank', 'fileBank')"
              :class="{'form-field-error': fieldsValidation.some(el => el.propertyName === 'BankVerificationFile')}"
            />
          </div>
          <div class="note">Please upload an unredacted copy of your bank statement for your account you wish to use for deposits and withdrawals, no older than six months. (10Mb max)</div>
        </div>

        <div class="field-wr">
          <div class="max-width-field">
            <vue-file-input
              class="file-input"
              type="file"
              name="proof-of-fund"
              :value="model.fileNameFunding"
              label="Proof of Funding Verification"
              accept=".png, .jpg, .jpeg, .pdf"
              @change="loadFile($event, 'fileNameFunding', 'fileFunding')"
              :class="{'form-field-error': fieldsValidation.some(el => el.propertyName === 'ProofOfFundingVerificationFile')}"
            />
          </div>
          <div class="note">Please upload a document showing your proof of funding. (10Mb max)</div>
        </div>

        <div v-if="validationErrors.length" class="geoff-text-danger mt-3">
          <div v-for="(err, idx) in validationErrors" :key="idx">
            <div>{{ err }}</div>
          </div>
        </div>

        <vue-button cls="main" class="my-5">Submit</vue-button>

      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "@/components/UI/PreLoader";
import VueInput from "@/components/UI/VueInput";
import VueFileInput from "@/components/UI/VueFileInput";
import VueButton from "@/components/UI/VueButton";

export default {
  name: "AccountVerificationView",
  components: {
    PreLoader,
    VueInput,
    VueFileInput,
    VueButton
  },

  data:()=>{
    return{
      loading: false,
      model: {
        firstName: '',
        lastName: '',
        userName: '',
        fileNameIdentity: null,
        fileIdentity: null,

        fileNameAddress: null,
        fileAddress: null,

        fileNameBank: null,
        fileBank: null,

        fileNameFunding: null,
        fileFunding: null,

      },
      frontErrors: [],
      backErrors: [],
      backValidErrors: []
    }
  },
  computed: {
    ...mapGetters(['USER']),
    validationErrors () {
      return this.fieldsValidation.map(el => el.message)
    },
    fieldsValidation () {
      if (this.frontErrors?.length) {
        return this.frontErrors
      }
      if (this.backErrors?.length) {
        return this.backErrors
      }
      if (this.backValidErrors) {
          const res = Object.keys(this.backValidErrors).map(el => { return {
            field: el,
            message: this.backValidErrors[el].find((el => el))
          }}).flat()
          return res
        }
      return []
    },
  },
  mounted() {
    this.setInitValues()
  },
  methods: {
    setInitValues() {
      this.model.firstName = this.USER?.first_name
      this.model.lastName = this.USER?.last_name
      this.model.userName = this.USER?.cust_Ref
    },

    loadFile(e, fileName, file){
      const vm = this
      let files = e.target.files
      if (!files.length) return
      vm.model[fileName] = files[0].name
      vm.model[file] = files[0]
    },

    validateForm() {
      const errors = []

      // NAME
      if (!this.model.firstName) {
        errors.push({
          field: 'FirstName',
          message: 'First Name is required.'
        })
      }
      if (!this.model.lastName) {
        errors.push({
          field: 'LastName',
          message: 'Last Name is required.'
        })
      }
      if (!this.model.userName) {
        errors.push({
          field: 'Username',
          message: 'Username is required.'
        })
      }


      // Any file is provided
      if (!this.model.fileIdentity
        && !this.model.fileAddress
        && !this.model.fileBank
        && !this.model.fileFunding
      ) {
        errors.push({
          // field: 'fileIdentity',
          message: 'Please upload at least one verification file.'
        })
      }
      let totalSize = 0
      let totalSizeFields = []

      // file IDENTITY
      if (this.model.fileIdentity) {
        totalSize += this.model.fileIdentity.size
        totalSizeFields.push('IdentityVerificationFile')
        // file size
        if( this.model.fileIdentity.size/ 1024 / 1024 > this.API.fileValidation.singleMb) {
          errors.push({
            propertyName: 'IdentityVerificationFile',
            message: `Identity Verification file must be ${this.API.fileValidation.singleMb}Mb max.`
          })
        }
        // file extention
        const extIdentity = this.model.fileNameIdentity.split('.').pop()
        if(!this.API.fileValidation.formats.includes(extIdentity)) {
          errors.push({
            propertyName: 'IdentityVerificationFile',
            message: this.API.fileValidation.errorMessageByName('Identity Verification')
          })
        }
      }

      // file ADDRESS
      if (this.model.fileAddress) {
        totalSize += this.model.fileAddress.size
        totalSizeFields.push('AddressVerificationFile')
        // file size
        if(this.model.fileAddress.size/ 1024 / 1024 > this.API.fileValidation.singleMb) {
          errors.push({
            propertyName: 'AddressVerificationFile',
            message: `Address Verification file must be ${this.API.fileValidation.singleMb}Mb max.`
          })
        }
        // file extention
        const extFileAddress = this.model.fileNameAddress.split('.').pop()
        if(!this.API.fileValidation.formats.includes(extFileAddress)) {
          errors.push({
            propertyName: 'AddressVerificationFile',
            message: this.API.fileValidation.errorMessageByName('Address Verification')
          })
        }
      }

      // file BANK
      if (this.model.fileBank) {
        totalSize += this.model.fileBank.size
        totalSizeFields.push('BankVerificationFile')
        // file size
        if(this.model.fileBank.size/ 1024 / 1024 > this.API.fileValidation.singleMb) {
          errors.push({
            propertyName: 'BankVerificationFile',
            message: `Bank Verification file must be ${this.API.fileValidation.singleMb}Mb max.`
          })
        }
        // file extention
        const extFileBank = this.model.fileNameBank.split('.').pop()
        if(!this.API.fileValidation.formats.includes(extFileBank)) {
          errors.push({
            propertyName: 'BankVerificationFile',
            message: this.API.fileValidation.errorMessageByName('Bank Verification')
          })
        }
      }

      // file FUNDING
      if (this.model.fileFunding) {
        totalSize += this.model.fileFunding.size
        totalSizeFields.push('ProofOfFundingVerificationFile')
        // file size
        if(this.model.fileFunding.size/ 1024 / 1024 > this.API.fileValidation.singleMb) {
          errors.push({
            propertyName: 'ProofOfFundingVerificationFile',
            message: `Proof of Funding Verification file must be ${this.API.fileValidation.singleMb}Mb max.`
          })
        }
        // file extention
        const extFileFunding = this.model.fileNameFunding.split('.').pop()
        if(!this.API.fileValidation.formats.includes(extFileFunding)) {
          errors.push({
            propertyName: 'ProofOfFundingVerificationFile',
            message: this.API.fileValidation.errorMessageByName('Proof of Funding Verification')
          })
        }
      }

      // TODO MAX SIZE SUM
      if(totalSize/ 1024 / 1024 > this.API.fileValidation.formMb) {
        totalSizeFields.forEach(fl => {
          errors.push({
            propertyName: fl
          })
        })
        errors.push({
          message: `Total file size exceeded. The maximum allowed total size of files is ${this.API.fileValidation.formMb}Mb`
        })
      }

      return errors;
    },

    submitForm() {
      // validate
      const validationResult =  this.validateForm()
      if (validationResult.length) {
        this.frontErrors = validationResult
        this.$toast.error('One or more fields are invalid. Please fix and try again.')
        return
      }
      else {
        this.frontErrors = []
      }
    

      // model
      let formData = new FormData()

      // TODO: prefill for logged in users
      formData.append("Firstname", this.model.firstName)
      formData.append("Lastname", this.model.lastName)
      formData.append("Username", this.model.userName)

      // docs
      if(this.model.fileIdentity) {
        formData.append('IdentityVerificationFile', this.model.fileIdentity)
      }
      if(this.model.fileAddress) {
        formData.append('AddressVerificationFile', this.model.fileAddress)
      }
      if(this.model.fileBank) {
        formData.append('BankVerificationFile', this.model.fileBank)
      }
      if(this.model.fileFunding) {
        formData.append('ProofOfFundingVerificationFile', this.model.fileFunding)
      }
   
      this.loading = true;

      this.API.postAccountVerificationForm(formData)
        .then((resp) => {
          if (resp.isFormSent) {
            this.$toast.success("Your request succesfully submited!")
            this.backErrors = null
            this.clearForm()
          }
          else {
            this.backErrors = resp?.fileValidationErrors
            this.$toast.error('One or more fields are invalid. Please fix and try again.')
          }
        })
        .catch(err => {
          console.log('FORM SUBMIT ERROR', err?.response?.data?.errors)
          if (err?.response?.data?.errors) {
            this.backValidErrors = err.response.data.errors
          }
          else {
            this.$toast.error('Service is unavailable. Please try again later.')
          }
        })
        .finally(()=>{
          this.loading = false
        })
    },

    clearForm() {
        this.model.fileNameIdentity =  null
        this.model.fileIdentity =  null
        this.model.fileNameAddress =  null
        this.model.fileAddress =  null
        this.model.fileNameBank =  null
        this.model.fileBank =  null
        this.model.fileNameFunding =  null
        this.model.fileFunding =  null
        this.model.firstName = ''
        this.model.lastName = ''
        this.model.userName = ''
      }
  
  }
}
</script>

<style scoped lang="scss">
  .form-page {
    .header {
      .title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
    }
    
    .field-wr {
      margin-bottom: 1rem;
      .label {
        margin-bottom: 0.5rem;
        font-size: 0.875rem;

        &.lbl-thin {
          font-size: 0.875rem;
          font-weight: 400;
        }
      }
      .field-label {
        font-size: 14px;
        line-height: 17px;
      }
      .note {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--geoff-txt-info);
      }

      .columns-2 {
        display: flex;
        flex-grow: 1;
        align-items: center;
        gap: 1.5rem;
        .col-input {
          flex-grow: 1;
          margin: 0 0 !important;
        }
      }

      // file input
      .file-input {
        .file-label {
          padding: 0.5rem 4rem 0.5rem 1rem !important;
        }
      }
    }
  }
</style>