<template>
  <div class="p-3 h-full-view">
    <pre-loader v-if="loading || ( step==='setAmount' && (!isUserLoaded || !isLimitLoaded))"/>
    <div class="deposit-wrap m-auto" v-show="!loading">

      <div v-if="isDevelopmentMode" class="alert alert-danger my-3" role="alert">Payment works in test mode!</div>
      <div v-show="step==='setAmount'">
        <div class="fs-18 fw-600 my-4">Deposit</div>
        <div v-show="isLimitLoaded && depositLimitData.isDepositAllowed">
          <input type="number" :step="1" class="input-value" v-model="amount">
          <div v-show="limitError" class="limit-error my-3"> 
            {{ limitError }} 
          </div>
  
          <div v-show="!limitError">
            <vue-button
              cls="main"
              class="my-4 deposit-button"
              @click="approveAmountForPayfoo"
            >Deposit by card</vue-button>
  
            <div ref="paypal" class="d-flex justify-content-center mb-4"></div>
  
            <!-- GOOGLEPAY -->
            <!-- <div>
              <form 
                :action="formActionApplePay"
                class="paymentWidgets"
                data-brands="GOOGLEPAY">
              </form>
            </div> -->
  
            <div v-if="$store.state.platform === 'web'">
              <form 
                :action="formActionApplePay"
                class="paymentWidgets"
                data-brands="APPLEPAY">
              </form>
            </div>
  
          </div>
            
        </div>
        <div v-if="isLimitLoaded && !depositLimitData.isDepositAllowed">
          <div>You have reached your net deposit limit. To change your deposit limit, <router-link class="geoff-link-accent text-decoration-none" :to="{name:'updateDepositLimit'}">click here</router-link>.</div>
        </div>
      </div>
      <div v-if="step==='payment'">
        <form
          :action="formActionCard"
          class="paymentWidgets"
          data-brands="MASTER VISA"
        > </form>
      </div>
      <div v-else-if="step==='success'">
        <div class="success-picture" :style="{backgroundImage: 'url('+require('../../assets/img/checkCircle.png')+')'}"></div>
        <div class="fs-3 text-success text-center my-4">Payment completed successfully!</div>
        <vue-button cls="main" @click="$router.push({name: 'home'})">Return to the homepage</vue-button>
      </div>
      <div v-else-if="step==='failed'">
        <div class="failed-picture" :style="{backgroundImage: 'url('+require('../../assets/img/warningIcon.png')+')'}"></div>
        <div class="fs-3 text-danger text-center my-4">{{ errorMessage }}</div>
        <vue-button cls="main" @click="reload">Try again</vue-button>
        <vue-button class="mt-3" @click="$router.push({name: 'home'})">Return to the homepage</vue-button>
      </div>
      <!-- legal info -->
      <div class="mt-3 fs-12 grid-legal-info desktop-gap ">
        <div class="col-label">Registered company name:</div><div class="col-info">GB Sports Advisors Ltd</div>
        <div class="col-label">Registered Address:</div><div  class="col-info">328-334 Molesey Road, Walton-On-Thames, Surrey, United Kingdom, KT12 3PD</div>
        <div class="col-label">Country:</div><div  class="col-info">United Kingdom</div>
        <div class="col-label">Email address:</div><div  class="col-info"> <a href="mailto:support@geoffbanks.bet">support@geoffbanks.bet</a> </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import moment from "moment";
  import { mapGetters } from "vuex";
  import { useToast } from "vue-toastification";
  import VueButton from "@/components/UI/VueButton";
  import PreLoader from "@/components/UI/PreLoader";
  
  export default {
    name: "CardDepositView",
    components: {
      PreLoader,
      VueButton, 
      //VueInput
    },
    data:()=>{
      return{
        step: 'setAmount',
        errorMessage: 'Payment declined!',
        loading: true,
        amount: null, //10.00,
        amountApproved: false,
        checkoutId: null,
        checkoutIdExpire: null,
        basePaydooUri: null,
        formActionApplePay: '/payment/deposit/applepay',
        formActionCard: '/payment/deposit/card',
        isDevelopmentMode: false,
        isLimitLoaded: false,
        depositLimitData: null
      }
    },
    setup(){
      const toast = useToast()
      return { toast }
    },
    watch:{
      isUserLoaded(val) {
        if (val) {
          this.getLimitData()
          // this.loading = false
        }
      },
      amount(value){
        console.log('CHANGE AMOUNT',value)
        // set wpwlOptions 
        const amount = this?.amount ? Number(this.amount).toFixed(2) : '10.00';
        // GOOGLEPAY
        // window['wpwlOptions']['googlePay']['amount'] = amount
        window['wpwlOptions']['applePay']['total']['amount'] = amount
        window['wpwlOptions']['checkout']['amount'] = amount
      }
    },
    computed: {
      ...mapGetters(['CURRENCY', 'USER']),
      depositInputLabel() {
        return `Enter Amount of Deposit, ${this.CURRENCY.symbol} (minimum ${this.CURRENCY.symbol}${this.depositLimitData?.minDeposit})`
      },
      isUserLoaded() {
        return (this.$store.state.user.userInfoLoaded && this.$store.state.user.userDetailsLoaded)
      },
      limitError() {
        if (this.isUserLoaded && this.depositLimitData?.isDepositAllowed) {
          const number = parseFloat(this.amount)
          // empty field 
          if ( isNaN(number)) {
            return 'Please enter a valid value.'
          }
          // minimum check
          if (number < this.depositLimitData.minDeposit) {
            // depositLimitData.minDeposit
            return `Minimum amount for deposit is ${this.CURRENCY.symbol}${this.depositLimitData.minDeposit}`
          }
          // maximum check
          if (this.depositLimitData.currentRemainingLimit > 0 && number > this.depositLimitData.currentRemainingLimit) {
            return `The maximum you are able to deposit is ${this.CURRENCY.symbol}${this.depositLimitData.currentRemainingLimit}`
          }
        }
        return ''
      }
    },
    beforeMount() {
      this.injectPayantFastCheckout()
    },
    mounted() {
      this.isDevelopmentMode = process.env.VUE_APP_MODE==='development'
      if (this.isUserLoaded) {
        this.getLimitData()
        // this.loading = false
      }
      this.injectPaypalJS()
      this.addPaydooOptions()
    },
    methods:{
      getLimitData() {
        this.API.getDepositLimit()
          .then(resp => {
            this.depositLimitData = resp
            this.depositLimitData.minDeposit = this.depositLimitData.minDeposit || 10
            this.amount = this.depositLimitData.minDeposit
            
            // this.depositLimitData.currentRemainingLimit = 0
            // this.depositLimitData.isDepositAllowed = false
            
            console.log('getDepositLimit resp', resp)
          })
          .catch(err => {
            console.log('getDepositLimit error', err)
            this.depositLimitData = {
              currentRemainingLimit: 0,
              isDepositAllowed: true,
              minDeposit: 10
            }
            this.amount = this.depositLimitData.minDeposit
  
            // this.reload()
          })
          .finally(() => {
            this.isLimitLoaded = true
          })
      },
      reload(){window.location.reload()},
      approveAmountForPayfoo(){
        const vm = this
        if (vm.amount < this.depositLimitData.minDeposit) return vm.toast.warning(`Minimum amount for deposit is ${this.CURRENCY.symbol}${this.depositLimitData.minDeposit}`)
        vm.loading = true
        vm.amountApproved = true
        vm.API.getCheckoutIdForPaydoo({
              amount: Number(vm.amount).toFixed(2),
              currency: this.CURRENCY.code,
              paymentType: 'DB',
              user: JSON.parse(localStorage.getItem('userCreds')).username
          })
        .then(data=>{
          if (new Date().getTime() < moment(data.timestamp).add(30, 'minutes').valueOf()) {
            vm.basePaydooUri = data.url
            vm.checkoutId = data.id
            vm.checkoutIdExpire = Date.parse(data.timestamp) + 60*60000
            vm.insertPaydooRenderFormJS()
            vm.step = 'payment'
          } else {
              vm.$toast.warning("Time to pay is over!")
              vm.basePaydooUri = null
              vm.checkoutId = null
          }
        })
        .finally(()=>vm.loading=false)
      },
      insertPaydooRenderFormJS(){
        let script = document.createElement('script');
        let head = document.getElementsByTagName('head')[0];
        script.type = 'text/javascript';
        script.src  = this.basePaydooUri;
        head.appendChild(script);
      },
      addPaydooOptions(){
        window['wpwlOptions'] = {
              maskCvv: true,
              style: 'plain',
              brandDetection: true,
              registrations: {
                  requireCvv: true
              },
              onReady: function() {
                  var createRegistrationHtml = '<div class="customLabel">Store payment details?</div>' +
                      '<div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
                  document.querySelector('form.wpwl-form-card').querySelector('.wpwl-button').insertAdjacentHTML('beforebegin', createRegistrationHtml);
              },
              // GOOGLEPAY
              // googlePay: {
              //   // DEV ENV
              //   // gatewayMerchantId: "8ac7a4c890fc748b0190fe15fcbf0192", // snadbox 
              //   // PROD ENV
              //   // gatewayMerchantId: "8acda4cc7d093938017d294c070206ad", // prod 
              //   // merchantId: "11995", // prod
              //   gatewayMerchantId: `${process.env.VUE_APP_PAYNT_GOOGLE_GATETWAY}`,
              //   merchantId: `${process.env.VUE_APP_PAYNT_GOOGLE_MERCHANT_ID}`,
              //   merchantName: "Geoff Banks Online", 
              //   currencyCode : this.CURRENCY.code,
              //   amount : '10',
              //   buttonColor: "white"
              // },
              applePay: {
                version:3,
                buttonSource: "js",
                buttonStyle: "black",
                buttonType: "pay",
                displayName: "GEOFF BANKS",
                total: {
                  label: "GEOFF BANKS" ,
                  amount : '10.00',
                },
                currencyCode : this.CURRENCY.code,
                checkAvailability: "canMakePayments",
                merchantCapabilities: ["supports3DS", "supportsDebit"],
              },
              // fast checkout
              checkout : {
                  amount : "10.00"
              },
  
              createCheckout: (json) => {
                console.log('createCheckout json', json)

                const data = {
                  amount: Number(this.amount).toFixed(2),
                  currency: this.CURRENCY.code,
                  paymentType: 'DB',
                  user: this.USER.cust_Ref,
                  userFirstName: this.USER?.first_name,
                  userSurname: this.USER?.last_name,
                }
                return this.API.getCheckoutIdForFastCheckout(data)
                  .then(function(response) {
                    // Assume that your server returned the response containing checkoutId
                    return response.id;
                  })
              },
  
              
  
            }
      },
      // getPaydooPaymentStatus() {
      //   const vm = this
      //   vm.loading = true
      //   const username = JSON.parse(localStorage.getItem('userCreds')).username
      //   vm.API.getPaydooPaymentStatus(vm.checkoutId, username)
      //   .then(data=>{
      //     const successfullyRegex = /^(000\.000\.|000\.100\.1|000\.[36])/
      //     const successfullyManuallyRegex = /^(000\.400\.0[^3]|000\.400\.[0-1]{2}0)/
      //     const pendingStatusRegex = /^(000\.200)/
      //     // const formatValidationError = /^(200\.[123]|100\.[53][07]|800\.900|100\.[69]00\.500)/
      //     console.log('CODE', data.result.code)
      //     console.log('SUCCESS', successfullyRegex.test(data.result.code) || successfullyManuallyRegex.test(data.result.code))
      //     if (successfullyRegex.test(data.result.code) || successfullyManuallyRegex.test(data.result.code)) {
      //       vm.step = 'success'
      //     } else {
      //       if (pendingStatusRegex.test(data.result.code)) {
      //         vm.errorMessage = 'Transaction pending'
      //         setTimeout(()=>vm.getPaydooPaymentStatus(), 2000)
      //       }
      //       vm.step = 'failed'
      //       // vm.errorMessage = data.result.description
      //     }
      //   })
      //   .finally(()=>vm.loading=false)
      // },
      injectPayantFastCheckout() {
        const script = document.createElement('script');
        script.src = `${process.env.VUE_APP_PAYNT_FAST_CHECKOUT_SCRIPT}`
        document.body.appendChild(script)
      },
      injectPaypalJS() {
        const script = document.createElement('script');
        // script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=GBP&disable-funding=credit,card`
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}&currency=${this.CURRENCY.code}&disable-funding=credit,card`
        document.body.appendChild(script)
        script.onload = this.setPayPalHandlers
      },
      setPayPalHandlers() {
        const vm = this
        vm.loading = false
        this.$refs.paypal.innerHTML = ''
          window.paypal.Buttons({
          style: { color: 'black'},
          createOrder: (data, actions) => {
              if (vm.amount < this.depositLimitData.minDeposit) return vm.toast.warning(`Minimum amount for deposit is ${this.CURRENCY.symbol}${this.depositLimitData.minDeposit}`)
              return actions.order.create({
                  purchase_units: [
                    {
                      reference_id: JSON.parse(localStorage.getItem('userCreds')).username,
                      amount: {
                        value: vm.amount.toString(),
                        currency: this.CURRENCY.code
                      }
                    }
                  ]
              });
            },
            // Finalize the transaction after payer approval
            onApprove: (data, actions) => {
              return actions.order.capture().then((orderData)=>{
                vm.API.savePayPalDeposit(orderData)
                  .then(resp => {
                    if (resp.isPayerIdDifferent) {
                      vm.toast.warning(resp.errorMessage)
                      vm.errorMessage = resp.errorMessage
                      vm.step = 'failed'
                    }
                    if(resp.success) {
                      vm.step = 'success'
                    }
                  })
                  .catch(er => {
                    console.log('PAYPAL after order.capture error savePayPalDeposit', er)
                    vm.step = 'success'
                  })
              });
            },
            onCancel: function (data) {
              console.log(data)
              // Show a cancel page, or return to cart
            },
            onError: err => {
                console.error(err)
                vm.step = 'failed'
            }
        })
        .render(this.$refs.paypal)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .content-deposit{
    position: fixed;
    top: calc(3.5rem + env(safe-area-inset-top));
    bottom: 3.5rem;
    left: 0;
    right: 0;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
  }
  .deposit-wrap{
    max-width: 400px;
  }
  input{
    width: 100%;
    height: 3.125rem;
    background: #F0F1F5;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    border: none;
    overflow: hidden;
  
  }
  .success-picture, .failed-picture{
    height: 20vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 4rem 0 2rem;
  }
  </style>
  <style>
  .wpwl-button{
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 100%;
    background: #FFD60A;
    border: none;
    color: black;
  }
  .wpwl-button:hover{
    line-height: 1.5rem;
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    width: 100%;
    background: #debd16;
    border: none;
    color: black;
  }
  .wpwl-button:focus{
    background: #ffc700;
    color: #6D6D6D;
    transition: 0.1s;
    border: none;
  }
  .wpwl-control{
    width: 100%;
    height: 3.125rem;
    background: #F0F1F5;
    border-radius: 4px;
    border: none;
  }
  .wpwl-group-registration{
    border-radius: 4px;
    background: #F0F1F5;
  }
  .wpwl-registration{
    display: flex;
    align-items: center;
  }
  .wpwl-wrapper-registration-registrationId{
  }
  .deposit-button{
    line-height: 12px!important;
    font-size: 16px!important;
  }
  @media only screen and (min-width: 200px) {
    .deposit-button{
      line-height: 18px!important;
      font-size: 16px!important;
    }
  }
  @media only screen and (min-width: 300px) {
    .deposit-button{
      line-height: 20px!important;
      font-size: 18px!important;
    }
  }
  
  input.input-value {
    width: 100%;
    height: 3.125rem;
    background: var(--geoff-bg-btn-1);
    border-radius: 4px;
    padding: 0.5rem 1rem;
    border: none;
    overflow: hidden;
  
    &[disabled] {
      background-color: #333;
      color: #666;
      cursor: not-allowed;
    }
    &:active{
      border: none;
    }
    &:focus{
      border: none;
    }
    &:focus-visible{
      outline: none;
    }
  }
  
  .limit-error {
    color: var(--geoff-danger);
  }
  
  .wpwl-form {
    margin:0;
  
    .wpwl-group-button button,
    .wpwl-group-button apple-pay-button {
      width: 100%
    }
  }
  
  </style>