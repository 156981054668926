<template>
<vue-modal no-close="true" ref="modal">
  <template v-slot:header>
    <div class="p-3 bg-dark fw-600 text-white text-center">
      Update Required
    </div>
  </template>
  <template v-slot:default>
    <div class="pt-4 text-center">
      <div class="upgrade-logo">
        <svg viewBox="0 0 128 128" width="128" height="128" version="1.1"  fill="currentcolor">
            <path d="m 60.90625,7.984349 c -28.67767,0 -51.9375,23.259829 -51.9375,51.9375 0,16.676862 7.86714,31.512852 20.09375,41.000001 l -10.96875,10.65625 36,0.3125 0.75,-35.999999 -11.375,11.03125 C 34.61486,81.195821 28.75,71.254621 28.75,59.921849 c 0,-17.763035 14.39321,-32.15625 32.15625,-32.15625 0.77958,0 1.54683,0.03923 2.3125,0.09375 l 0,-19.8125 c -0.76301,-0.03329 -1.5413,-0.0625 -2.3125,-0.0625 z m 13,8.125 -0.75,36 11.375,-11.03125 C 93.38514,46.804126 99.25,56.745325 99.25,68.078101 c 0,17.76303 -14.39321,32.156249 -32.15625,32.156249 -0.779582,0 -1.546832,-0.0392 -2.3125,-0.0937 l 0,19.8125 c 0.763014,0.0333 1.541303,0.0625 2.3125,0.0625 28.67767,0 51.9375,-23.259829 51.9375,-51.937499 0,-16.676868 -7.86714,-31.512859 -20.09375,-41.000002 l 10.96875,-10.65625 -36,-0.3125 z"/>
        </svg>
      </div>
      <div class="upgrade-text">This version of the app is no longer supported. Please update to the latest version to continue using all features and improvements.</div>
      <vue-button @click="openApp" cls="main">Update Now</vue-button>
    </div>
  </template>
</vue-modal>
</template>

<script>
import { mapGetters } from "vuex";
import VueModal from "@/components/UI/VueModal";
import VueButton from "@/components/UI/VueButton";
export default {
  name: "AppUpdateModal",
  components: {
    VueModal,
    VueButton
  },
  computed: {
    ...mapGetters(['SETTINGS'])
  },
  watch: {
    'SETTINGS.is_app_version_supported'() {
      this.initPopupState()
    }
  },
  mounted() {
      this.initPopupState()
  },
  methods:{
    initPopupState() {
      if (this.SETTINGS?.is_app_version_supported === false) {
        this.$refs.modal.open()
      }
    },
    openApp(){
      let linkUrl = ''
      if (this.$store.state.platform === 'ios' ) {
        linkUrl = 'https://apps.apple.com/gb/app/geoff-banks-betting/id881898186'
      }
      if (this.$store.state.platform === 'android') {
        linkUrl = 'https://play.google.com/store/apps/details?id=com.geoff_banks.geoffbanks'
      }
      window.open(linkUrl , '_blank');
    }
  }
}
</script>

<style scoped>
.content{
  max-width: 400px;
}
.upgrade-logo {
  color: var(--geoff-accent);
  margin: 1rem 0;

  svg {
    width: 4rem;
    height: 4rem;
  }
}
.upgrade-text {
  margin: 1.5rem auto;
}

</style>